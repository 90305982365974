import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Map extends React.Component {
    render() {
      let { data } = this.props
      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }
      const options = {
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        region: '150',
      }

      // const rows = {
      //   rows: [['this', 12, '12'], [4, 5.5, '5.5']],
      // }
      const columns = {
        columns: [
          0,
          1,
          {
            label: 'stringify',
            sourceColumn: 1,
            type: 'string',
            role: 'annotation',
          },
        ],
      }
      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              width={'100%'}
              height={'800px'}
              chartType="GeoChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={options}
              // columns={columns.columns}
              // rows={rows.rows}
              setColumns={columns.columns}

              //rootProps={{ 'data-testid': '1' }}
            />
          )}
        </div>
      )
    }
  }
)
