import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Pie extends React.Component {
    render() {
      let { data, vaxis, haxis } = this.props
      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }
      const options = {
        legend: { position: 'bottom', alignment: 'start' },

        axes: {
          x: {
            0: { side: 'bottom' },
          },
          // y: {
          //   0: { side: 'left' },
          // },
        },

        slices: {
          0: { color: '#114999' },
          1: { color: '#6595C7' },
          2: { color: '#FFD703' },
          3: { color: '#E74315' },
          4: { color: '#75C0BC' },
          5: { color: '#D0D0D0' },
          6: { color: '#0E1C39' },
        },
        vAxis: {
          title: vaxis,
        },
        hAxis: {
          title: haxis,
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
      }

      const columns = {
        columns: [
          0,
          1,
          {
            label: 'stringify',
            sourceColumn: 1,
            type: 'string',
            role: 'annotation',
          },
        ],
      }
      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              width={'100%'}
              height={'400px'}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={options}
              // columns={columns.columns}
              // rows={rows.rows}
              setColumns={columns.columns}

              //rootProps={{ 'data-testid': '1' }}
            />
          )}
        </div>
      )
    }
  }
)
