import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CompanyProfile from './companyProfile'

class FeaturedCompanies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeProfile: null,
    }
  }
  showDetails = () => {
    return this.state.details
  }
  handleProfileClick(profile, j, company_text) {
    if (profile.props.active !== j) {
      this.setState({ activeProfile: j })
    } else {
      this.setState({ activeProfile: null })
    }
  }

  render() {
    return (
      <div className="row">
        <div className="container container-960">
          <div className="row">
            <div className="col-md-12">
              <div className="flex-container">
                {this.props.layout.company_profiles.map((profile, j) => (
                  <React.Fragment key={j}>
                    <CompanyProfile
                      active={this.state.activeProfile}
                      profile={profile}
                      profileKey={j}
                      layout={this.props.layout}
                      onProfileClick={(profile, j, company_text) =>
                        this.handleProfileClick(profile, j, company_text)
                      }
                    />
                    {/* {profile.company_text} */}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
CompanyProfile.propTypes = {
  //layout: PropTypes.object.isRequired,
}
export default FeaturedCompanies
