import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Line extends React.Component {
    render() {
      let {
        data,
        vaxis,
        haxis,
        title,
        vticks,
        vsuffix,
        hsuffix,
        hprefix,
        chart_scale_type,
      } = this.props
      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }

      let verticalTicks = null
      let scaleType = null
      let verticalSuffix = null

      if (vticks.length > 0) {
        scaleType = 'log'
        verticalTicks = vticks.split(',')
      }
      if (vsuffix.length > 0) {
        verticalSuffix = "#'" + vsuffix + "'"
      }

      const options = {
        legend: 'none',
        series: {
          0: { color: '#114999' },
          1: { color: '#E74315' },
        },
        title: title,
        vAxis: {
          title: vaxis,
          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
          format: verticalSuffix,
          scaleType: scaleType,
          ticks: verticalTicks,
        },
        hAxis: {
          title: haxis,
          format: 'Y',
          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        chartArea: {
          left: 120,
          right: 15,
          top: 20,
          bottom: 80,
        },
      }
      const chartEvents = [
        {
          eventName: 'select',
          callback({ chartWrapper }) {
            chartWrapper.draw(chartWrapper.getChart().container, options)
            console.log('Selected ', chartWrapper.getChart().container)
          },
        },
      ]
      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              width={'100%'}
              height={'400px'}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={options}
              chartEvents={chartEvents}
            />
          )}
        </div>
      )
    }
  }
)
