import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Bar extends React.Component {
    render() {
      let { data, vaxis, haxis, colour_scheme } = this.props
      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }
      let color = '114999'
      switch (colour_scheme) {
        case 'blue':
          color = '114999'
          break
        case 'orange':
          color = 'E74315'
          break
        case 'lightblue':
          color = '6595C7'
          break
      }

      const options = {
        legend: {
          position: 'none',
        },
        areaOpacity: 1,
        series: {
          0: { color: '#' + color },
        },
        hAxis: {
          title: haxis,
          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
        },
        vAxis: {
          title: vaxis,
          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        chartArea: {
          left: 80,
          right: 30,
          top: 20,
          bottom: 60,
        },
      }

      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              width={'100%'}
              height={'400px'}
              chartType="AreaChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={options}
              // For tests
              rootProps={{ 'data-testid': '1' }}
            />
          )}
        </div>
      )
    }
  }
)
