import React from 'react'
import PropTypes from 'prop-types'

const Table = ({ table_heading, table_data, table_source }) => {
  return (
    <div className="row table breathe">
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <h6
              className="source"
              dangerouslySetInnerHTML={{ __html: table_heading }}
            />
            <div
              className=" table-responsive"
              dangerouslySetInnerHTML={{
                __html: table_data,
              }}
            />
            <p
              className="source"
              dangerouslySetInnerHTML={{ __html: table_source }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
Table.propTypes = {
  table_heading: PropTypes.string.isRequired,
  table_data: PropTypes.string.isRequired,
  table_source: PropTypes.string.isRequired,
}

export default Table
