import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class BarDot extends React.Component {
    render() {
      let {
        data,
        vaxis,
        haxis,
        title,
        left_label_width,
        bottom_label_width,
      } = this.props

      let llw = 15
      let blw = 15
      if (left_label_width > 0) {
        llw = left_label_width
      }
      if (bottom_label_width > 0) {
        blw = bottom_label_width
      }

      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }
      const options = {
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            bold: true,
          },
        },
        title: title,

        vAxis: {
          0: { title: vaxis[0] },
          1: { title: vaxis[1] },
        },
        hAxis: {
          0: { title: haxis[0] },
          1: { title: haxis[1] },
          slantedText: true,
          slantedTextAngle: 90,
        },
        seriesType: 'bars',
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        chartArea: {
          left: llw + '%',
          right: 30,
          top: 20,
          bottom: blw + '%',
        },
        series: {
          0: {
            color: '#114999',
            targetAxisIndex: 0,
          },
          1: {
            color: '#E74315',
            title: '',
            //targetAxisIndex: 1,
            type: 'scatter',
            lineWidth: 0,
            pointShape: { type: 'circle' },
            pointStroke: { color: 'red', fill: 'white', width: 3 },
            pointSize: 10,
          },
          2: {
            color: '#FFD703',
            title: '',
            type: 'line',
            lineWidth: 2,
          },
        },
      }
      if (data[0][3]) {
        options.series[1].color = '#FFD703'
        options.series[2].color = '#E74315'
        console.log(data)
      }
      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              chartType="ComboChart"
              options={options}
              data={data}
              width="100%"
              height="500px"
              legendToggle
            />
          )}
        </div>
      )
    }
  }
)
