import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Column extends React.Component {
    render() {
      let {
        data,
        vaxis,
        haxis,
        vprefix,
        hprefix,
        vsuffix,
        hsuffix,
        colour_scheme,
        chart_scale_type,
        left_label_width,
        bottom_label_width,
      } = this.props

      let verticalSuffix = null
      let horizontalSuffix = null
      let llw = 15
      let blw = 15
      if (left_label_width > 0) {
        llw = left_label_width
      }
      if (bottom_label_width > 0) {
        blw = bottom_label_width
      }
      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }
      if (vsuffix.length > 0) {
        verticalSuffix = "#'" + vsuffix + "'"
      }
      if (hsuffix.length > 0) {
        horizontalSuffix = "#'" + hsuffix + "'"
      }

      const options = {
        // legend: {
        //   position: 'top',
        //   alignment: 'center',
        //   textStyle: {
        //     bold: true,
        //   },
        // },
        legend: 'none',

        series: {
          0: { color: '#114999' },
        },
        vAxis: {
          title: vaxis,
          format: verticalSuffix,

          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
        },
        hAxis: {
          title: haxis,
          format: horizontalSuffix,
          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        chartArea: {
          left: llw + '%',
          right: 30,
          top: 20,
          bottom: blw + '%',
        },
      }

      switch (colour_scheme) {
        case 'blue':
          options.series[0].color = '#114999'
          break
        case 'orange':
          options.series[0].color = '#E74315'
          break
        case 'lightblue':
          options.series[0].color = '#6595C7'
          break
        case 'turquoise':
          options.series[0].color = '#75C0BC'
          break
      }

      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              chartType="ColumnChart"
              options={options}
              data={data}
              width="100%"
              height="500px"
            />
          )}
        </div>
      )
    }
  }
)
