import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Bar extends React.Component {
    render() {
      let {
        data,
        vaxis,
        haxis,
        vsuffix,
        hsuffix,
        vticks,
        hticks,
        vprefix,
        hprefix,
        colour_scheme,
        chart_scale_type,
        left_label_width,
        bottom_label_width,
      } = this.props
      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }

      let color = '114999'
      switch (colour_scheme) {
        case 'blue':
          color = '114999'
          break
        case 'orange':
          color = 'E74315'
          break
        case 'lightblue':
          color = '6595C7'
          break
      }

      let verticalSuffix = null
      let horizontalSuffix = null
      let verticalTicks = null
      let horizontalTicks = null
      let horizontalPrefix = null
      let verticalPrefix = null
      let llw = 30
      let blw = 15
      if (left_label_width > 0) {
        llw = left_label_width
      }
      if (bottom_label_width > 0) {
        blw = bottom_label_width
      }

      if (vticks.length > 0) {
        verticalTicks = vticks.split(',')
      }
      if (hticks.length > 0) {
        horizontalTicks = hticks.split(',')
      }

      if (vsuffix.length > 0) {
        verticalSuffix = "#'" + vsuffix + "'"
      }
      if (hsuffix.length > 0) {
        horizontalSuffix = "#'" + hsuffix + "'"
      }
      if (vprefix.length > 0) {
        verticalPrefix = vprefix + "#'"
      }
      if (hprefix.length > 0) {
        horizontalPrefix = hprefix + "#'"
      }

      let formatters = {
        date: [
          {
            columnNum: 1,
            pattern: 'HH:mm:ss dd:MM:yy',
          },
        ],
      }
      const options = {
        legend: 'none',

        //legend: { position: 'bottom', alignment: 'start' },

        axes: {
          x: {
            0: { side: 'bottom' },
          },

          // y: {
          //   0: { side: 'left' },
          // },
        },
        vAxis: {
          title: vaxis,
          format: verticalSuffix,
          textStyle: {
            fontSize: 12,
          },
          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
        },
        hAxis: {
          title: haxis,
          format: horizontalSuffix || horizontalPrefix,
          ticks: horizontalTicks,
          titleTextStyle: {
            color: '#000',
            fontName: 'canada-type-gibson',
            fontSize: 14,
            bold: false,
            italic: false,
          },
          //gridlines: { count: 4 },
        },
        series: {
          0: { color: '#' + color },
        },
        formatters: formatters,
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        chartArea: {
          left: llw + '%',
          right: 30,
          top: 20,
          bottom: blw + '%',
        },
      }

      // const rows = {
      //   rows: [['this', 12, '12'], [4, 5.5, '5.5']],
      // }
      const columns = {
        columns: [
          0,
          1,
          {
            label: 'stringify',
            sourceColumn: 1,
            type: 'string',
            role: 'annotation',
          },
        ],
      }
      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              width={'100%'}
              height={'400px'}
              chartType="BarChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={options}
              // columns={columns.columns}
              // rows={rows.rows}
              setColumns={columns.columns}
              //rootProps={{ 'data-testid': '1' }}
            />
          )}
        </div>
      )
    }
  }
)
