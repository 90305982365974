import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Stacked extends React.Component {
    render() {
      let { data, vaxis, haxis, title, ticks } = this.props
      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }
      const options = {
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            bold: true,
          },
        },
        series: {
          0: { color: '#114999' },
          1: { color: '#FFD703' },
          2: { color: '#E74315' },
          3: { color: '#75C0BC' },
          4: { color: '#6595C7' },
          5: { color: '#D0D0D0' },
          6: { color: '#0E1C39' },
        },
        title: title,

        vAxis: {
          title: vaxis,
        },
        hAxis: {
          title: haxis,
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        isStacked: 'percent',
      }

      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              chartType="BarChart"
              options={options}
              data={data}
              width="100%"
              height="400px"
            />
          )}
        </div>
      )
    }
  }
)
