import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class BarLine extends React.Component {
    render() {
      let {
        data,
        vaxis,
        vaxis2,
        vticks,
        hticks,
        vsuffix,
        vaxis_right_suffix,
        left_label_width,
        bottom_label_width,
      } = this.props

      let verticalSuffix = null
      let verticalTicks = null
      let horizontalTicks = null
      let verticalSuffixRight = null
      let llw = 15
      let blw = 15
      if (left_label_width > 0) {
        llw = left_label_width
      }
      if (bottom_label_width > 0) {
        blw = bottom_label_width
      }

      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }
      if (vticks.length > 0) {
        verticalTicks = vticks.split(',')
      }
      if (hticks.length > 0) {
        horizontalTicks = hticks.split(',')
      }
      if (vsuffix.length > 0) {
        verticalSuffix = "#'" + vsuffix + "'"
      }
      if (vaxis_right_suffix.length > 0) {
        verticalSuffixRight = "#'" + vaxis_right_suffix + "'"
      }

      const options = {
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            fontName: 'canada-type-gibson',
            bold: false,
          },
        },
        vAxes: {
          0: {
            title: vaxis,
            format: verticalSuffix,
            gridlines: { color: 'transparent' },
          },
          1: {
            title: vaxis2,
            format: '0',
            format: verticalSuffixRight,
            ticks: verticalTicks,
          },
        },
        hAxis: {
          ticks: horizontalTicks,
        },
        seriesType: 'bars',
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        chartArea: {
          left: llw + '%',
          right: 70,
          top: 20,
          bottom: blw + '%',
        },
        series: {
          0: {
            color: '#114999',
            targetAxisIndex: 0,
            title: 'vaxis[0]',
          },
          1: {
            color: '#E74315',
            targetAxisIndex: 1,
            type: 'line',
            lineWidth: 3,
            pointShape: { type: 'circle' },
            pointStroke: { color: '#E74315', fill: 'white', width: 3 },
            pointSize: 10,
          },
        },
      }

      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              chartType="ComboChart"
              options={options}
              data={data}
              width="100%"
              height="400px"
              legendToggle
            />
          )}
        </div>
      )
    }
  }
)
