import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CompanyProfile extends Component {
  showDetails = () => {
    return this.props.details
  }

  render() {
    let classes = 'company-details col-xs-12 flex-item '
    classes += this.props.profileKey % 2 ? ' left ' : ' right '
    classes +=
      this.props.active === this.props.profileKey ? 'active' : 'inactive'

    return (
      <React.Fragment>
        <div
          className={
            this.props.active === this.props.profileKey
              ? 'col-xs-12 flex-item company-profile  active'
              : 'col-xs-12 flex-item company-profile  inactive'
          }
          onClick={() =>
            this.props.onProfileClick(
              this,
              this.props.profileKey,
              this.props.profile.company_text
            )
          }
          style={{ order: this.props.profileKey }}
        >
          <span className="openCloseIcon" />
          <div className="company-profile-cover">
            <div className="company-profile-cover-inner">
              <img
                src={this.props.profile.company_logo.source_url}
                className="img-responsive"
              />
            </div>
          </div>
        </div>

        <div
          className={classes}
          style={{
            order:
              this.props.profileKey % 2
                ? this.props.profileKey + 1
                : this.props.profileKey + 2,
          }}
        >
          <div
            className="company-details-inner"
            dangerouslySetInnerHTML={{
              __html: this.props.profile.company_text,
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

CompanyProfile.propTypes = {
  //profile: PropTypes.object.isRequired,
}

export default CompanyProfile
