import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class TwoBarWithLine extends React.Component {
    render() {
      let {
        data,
        vaxis,
        vaxis2,
        hticks,
        vticks,
        vsuffix,
        vaxis_right_suffix,
      } = this.props

      let verticalSuffix = null
      let verticalTicks = null
      let horizontalTicks = null
      let verticalSuffixRight = null

      if (this.props.isInViewport) {
        this.props.stopWatcher()
      }

      if (hticks.length > 0) {
        horizontalTicks = hticks.split(',')
      }
      if (vticks.length > 0) {
        verticalTicks = vticks.split(',')
      }
      if (vsuffix.length > 0) {
        verticalSuffix = "#'" + vsuffix + "'"
      }
      if (vaxis_right_suffix.length > 0) {
        verticalSuffixRight = "#'" + vaxis_right_suffix + "'"
      }

      const options = {
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            bold: true,
          },
        },
        vAxes: {
          0: {
            title: vaxis,
            format: verticalSuffix,

            ticks: verticalTicks,
            titleTextStyle: {
              color: '#000',
              fontName: 'canada-type-gibson',
              fontSize: 14,
              bold: false,
              italic: false,
            },
          },
          1: {
            title: vaxis2,
            format: '0',
            format: verticalSuffixRight,
            gridlines: { color: 'transparent' },
            titleTextStyle: {
              color: '#000',
              fontName: 'canada-type-gibson',
              fontSize: 14,
              bold: false,
              italic: false,
            },
          },
        },
        seriesType: 'bars',
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        series: {
          0: {
            color: '#114999',
            targetAxisIndex: 0,
          },
          1: {
            color: '#FFD703',
            targetAxisIndex: 0,
          },
          2: {
            color: '#E74315',
            title: '',
            targetAxisIndex: 1,
            type: 'line',
            lineWidth: 3,
          },
        },
      }

      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              chartType="ComboChart"
              options={options}
              data={data}
              width="100%"
              height="400px"
              legendToggle
            />
          )}
        </div>
      )
    }
  }
)
